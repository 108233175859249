<template>
    <div>
        <v-card v-if="document && document.locationDocument">
            <v-card-title>
                <v-chip
              small
              class="mr-2"
              :color="
                document.approvedCount >= document.requiredCount ? 'green' : 'darkgrey'
              "
            >
              {{ document.approvedCount }} /
              {{ document.requiredCount }}
            </v-chip>
                {{document.locationDocument.name}} <v-spacer></v-spacer><v-btn text @click="$emit('close')">X</v-btn></v-card-title>
            <v-card-text>
          <p style="color: grey">{{ document.locationDocument.description }}</p>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
export default {
    props: ['document']
}
</script>